<template>
  <div class="card">
    <div class="card__header">
      <div class="card__title">
        <h3 class="title">{{ fullName }}</h3>
        <p class="subtitle">Табельный номер: <span>12345678</span></p>
      </div>
    </div>
    <div class="card__body w-50">
      <EmployeeForm :disabled="!employeesStore.isEditMode" />
    </div>
    <div class="card__footer" v-if="employeesStore.isEditMode">
      <Button
        variant="solid"
        color="secondary"
        @click="employeesStore.toggleEditMode()"
        :style="{
          width: '160px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="editEmployee"
        :style="{
          width: '206px',
        }"
      >
        Сохранить
      </Button>
    </div>
  </div>

  <DeleteModal
    :isShowModal="employeesStore.isShowDeleteModal"
    title="Удалить пользователя?"
    :subtitle="employees?.name"
    @close="employeesStore.setDeleteModal(false)"
    @delete="deleteEmployee"
  />
</template>

<script setup>
import DeleteModal from "@/components/modals/DeleteModal.vue";
import EmployeeForm from "../components/EmployeeForm.vue";
import { computed, onMounted, ref } from "vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import Button from "@/components/ui/Button/Button.vue";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { useRoute, useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const employeesStore = useEmployeesStore();
const fullName = computed(() => employeesStore.employee.name.value);

const employees = ref();
const route = useRoute();
const router = useRouter();

const editEmployee = () => {
  if (!employeesStore.validateForm()) {
    return;
  }
  EmployeesAPI.update(employeesStore.getValues, route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь успешно изменен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};

const deleteEmployee = () => {
  EmployeesAPI.delete(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Удалено",
      });

      window.dispatchEvent(new CustomEvent("updateTable"));
      router.push("/employees");
    })
    .catch((err) => {
      notifications(err);
    })
    .finally(() => {
      employeesStore.setDeleteModal(false);
    });
};

const getEmployee = () => {
  EmployeesAPI.getById(route.params.id)
    .then((res) => {
      const response = res.data;
      employeesStore.setEmployee({
        name: response.name,
        surname: response.surname,
        patronymic: response.patronymic,
        email: response.email,
        birthday: response.birthday,
        departmentId: response.departmentId,
        positionId: response.positionId,
        banned: !!response.banned,
      });
    })
    .catch((err) => {
      notifications(err);
    });
};

onMounted(() => {
  getEmployee();
});
</script>
