<template>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Активные роли</h3>
    </div>
    <div class="card__body">
      <Table
        :columns="activeRolesColumn"
        :data="activeRoles"
        :loading="loading"
      >
        <template #dropdownActions>
          <li>Отозвать</li>
          <li>Пересмотреть</li>
          <li>Изменить срок действия</li>
        </template>
      </Table>
    </div>
  </div>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Роли в обработке</h3>
    </div>
    <div class="card__body">
      <Table
        :columns="processingRolesColumn"
        :data="activeRoles"
        :loading="loading"
      />
    </div>
  </div>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Архив ролей</h3>
    </div>
    <div class="card__body">
      <Table
        :columns="archiveRolesColumn"
        :data="archiveRoles"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script setup>
import { EmployeesAPI } from "@/api/EmployeesAPI";
import Table from "@/components/table/Table.vue";
import { onMounted, ref } from "vue";

const activeRolesColumn = {
  id: { value: "ID" },
  name: { value: "Название роли" },
  author: { value: "Автор назначения " },
  validityPeriod: { value: "Срок действия" },
  date: { value: "Дата назначения" },
};

const processingRolesColumn = {
  id: { value: "ID" },
  name: { value: "Название роли" },
  author: { value: "Автор назначения " },
  status: { value: "Статус" },
  date: { value: "Дата назначения" },
};

const archiveRolesColumn = {
  id: { value: "ID" },
  name: { value: "Название роли" },
  date: { value: "Дата назначения" },
  author: { value: "Автор назначения " },
  date_review: { value: "Дата отзыва" },
  author_review: { value: "Автор отзыва" },
};

const activeRoles = ref([]);
const processingRoles = ref([]);
const archiveRoles = ref([]);

const fetchData = () => {
  EmployeesAPI.getAll()
    .then((res) => {
      activeRoles.value = res.data;
    })
    .finally(() => {
      activeRoles.value = [
        {
          id: 1,
          name: "Администратор ИБ",
          author: "Куликов Иван Владимирович",
          validityPeriod: "Бессрочно",
          date: "23 мая 2024",
        },
        {
          id: 2,
          name: "1С Базовый уровень доступа",
          author: "Перелеска Ирина Витальевна",
          validityPeriod: "06 апреля 2024",
          date: "02 марта 2024",
        },
      ];
      processingRoles.value = [
        {
          id: 1,
          name: "Администратор ИБ",
          author: "Куликов Иван Владимирович",
          status: "processing",
          date: "23 мая 2024",
        },
        {
          id: 2,
          name: "1С Базовый уровень доступа",
          author: "Перелеска Ирина Витальевна",
          status: "onapproval",
          date: "02 марта 2024",
        },
      ];
      archiveRoles.value = [
        {
          id: 1,
          name: "Администратор ИБ",
          date: "23 мая 2024",
          author: "Куликов Иван Владимирович",
          date_review: "18 мая 2024",
          author_review: "Марченко Андрей Анатольевич",
        },
        {
          id: 2,
          name: "Базовый уровень доступа",
          date: "23 февраля 2024",
          author: "Перелеска Ирина Витальевна",
          date_review: "24 февраля 2024",
          author_review: "Маркин Игорь Каримович",
        },
      ];
    });
};

onMounted(() => {
  fetchData();
});
</script>
