<template>
  <div class="card">
    <div class="card__header">
      <h3 class="title">Учетные записи</h3>
    </div>
    <div class="card__body">
      <Table :columns="accountsColumn" :data="accounts" :loading="loading">
        <template #dropdownActions>
          <li>Разблокировать</li>
          <li>Заблокировать</li>
          <li>Изменить пароль</li>
        </template>
      </Table>
    </div>
  </div>
</template>

<script setup>
import { EmployeesAPI } from "@/api/EmployeesAPI";
import Table from "@/components/table/Table.vue";
import { onMounted, ref } from "vue";

const accounts = ref([]);

const accountsColumn = {
  id: { value: "ID" },
  name: { value: "Наименование" },
  service: { value: "Сервис" },
  date: { value: "Дата создания" },
  lastLogin: { value: "Последний вход" },
  status: { value: "Статус" },
};

const fetchData = () => {
  EmployeesAPI.getAll()
    .then((res) => {
      accounts.value = res.data;
    })
    .finally(() => {
      accounts.value = [
        {
          id: 1,
          name: "1С_Organization45",
          service: "1С, Предприятие",
          date: "08 апреля 2024",
          lastLogin: "23 мая 2024",
          status: "active",
        },
        {
          id: 2,
          name: "1С_Organization45_123",
          service: "1С, Предприятие",
          date: "23 марта 2024",
          lastLogin: "02 апреля 2024",
          status: "blocked",
        },
      ];
    });
};

onMounted(() => {
  fetchData();
});
</script>
