<template>
  <div class="filter-overlay" @click="closeFilter">
    <div class="filter-content" @click.stop>
      <header class="filter-header">
        <svg viewBox="0 0 16 16" class="v-icon cancel" @click="closeFilter">
          <use href="#cancel" />
        </svg>
        <h3>Расширенный фильтр</h3>
      </header>
      <div class="filter-body">
        <Accordion :multiple="true">
          <AccordionTab
            :header="filter.title"
            v-for="filter in filters"
            :key="filter.name"
            class="filter-section"
          >
            <template v-if="filter.type === 'checkbox'">
              <SearchInput
                v-model="searchTerms[filter.name]"
                placeholder="Начните вводить…"
              />
              <Checkbox
                v-if="filterOptions(filter).length > 0"
                :id="filter.name"
                label="Выделить все"
                :checked="
                  selectedFilters[filter.name].length === filter.data.length
                "
                @change="
                  toggleSelectAll(
                    filter.name,
                    filter.data,
                    $event.target.checked
                  )
                "
              />
              <div
                class="checkbox-container"
                v-for="option in filterOptions(filter)"
                :key="option"
              >
                <Checkbox
                  :label="option"
                  :id="option"
                  :modelValue="selectedFilters[filter.name].includes(option)"
                  @update:modelValue="applyCheckboxFilter(filter.name, option)"
                />
              </div>
            </template>

            <template v-else-if="filter.type === 'date'">
              <div class="date-label">Период</div>
              <div class="date-container">
                <FormDate
                  v-model="localFilters[filter.name][0]"
                  placeholder="с"
                />
                <span>-</span>
                <FormDate
                  v-model="localFilters[filter.name][1]"
                  placeholder="по"
                />
              </div>
            </template>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="filter-footer">
        <Button full color="secondary" @click="clearFilters">Очистить</Button>
        <Button full @click="applyFilters">Применить фильтр</Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import Button from "@/components/ui/Button/Button.vue";
import { toRefs } from "vue";
import { ref } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import SearchInput from "./SearchInput.vue";
import Checkbox from "../ui/Checkbox/Checkbox.vue";

const props = defineProps({
  filters: {
    type: Array,
    required: true,
  },
  applyFilters: Function,
  selectedFilters: Object,
});

const { filters } = toRefs(props);
const localFilters = ref(props.selectedFilters);
const applyCheckboxFilter = (filterName, option) => {
  emit("updateFilter", filterName, option);
};

const searchTerms = ref(
  filters.value.reduce((acc, filter) => {
    if (filter.type === "checkbox") {
      acc[filter.name] = ""; // Создаем поле для поиска по каждому чекбоксу
    }
    return acc;
  }, {})
);

const emit = defineEmits(["updateFilter", "close", "resetFilters"]);

const toggleSelectAll = (filterName, allOptions, isChecked) => {
  if (isChecked) {
    emit("updateFilter", filterName, allOptions, true);
  } else {
    emit("updateFilter", filterName, [], true);
  }
};

const closeFilter = () => {
  emit("close");
};

const clearFilters = () => {
  emit("resetFilters");
};

const filterOptions = (filter) => {
  if (!searchTerms.value[filter.name]) {
    return filter.data;
  }
  const searchTerm = searchTerms.value[filter.name].toLowerCase();
  return filter.data.filter((option) =>
    option.toLowerCase().includes(searchTerm)
  );
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.filter-content {
  position: relative;
  width: 482px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  background: #f9fbff;
  box-shadow: -2px 0px 32px 0px #0000000f;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.filter-header {
  position: relative;
  padding: 0 32px;
  font-size: 28px;
  margin-bottom: 32px;

  h3 {
    margin-top: 52px;
  }

  .cancel {
    position: absolute;
    top: 16px;
    right: 32px;
    width: 36px;
    height: 36px;
    padding: 10px;
    background-color: #fff;
    border-radius: 9px;
    cursor: pointer;
  }
}

.filter-search {
  .v-icon {
    width: 48px;
  }
}

.filter-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 32px 32px;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: $theme200;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  .filter-section {
    margin-bottom: 32px;
    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $theme200;
      padding-bottom: 16px;
      cursor: pointer;
      h4 {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: $theme900;
      }
    }
    .arrow {
      transform: rotate(90deg);
      transition: transform 0.3s;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .checkbox-container {
    margin-top: 24px;
  }
}

.filter-footer {
  padding: 32px;
  display: flex;
  gap: 24px;
  box-shadow: 0px -2px 16px 0px #0000000a;

  > button:first-child {
    padding: 0;
  }
}

.cancel-button {
  background-color: #6c757d;
}

.close-button {
  font-size: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.date-label {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 700;
  color: $theme800;
}

.date-container {
  display: flex;
  gap: 4px;
  align-items: center;

  span {
    width: 16px;
    height: 16px;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>
