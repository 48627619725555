<template>
  <Button
    v-if="!isBanned"
    variant="outlined"
    color="default"
    icon="pi-times"
    @click="employeesStore.showFiredModal()"
    :style="{
      width: '127px',
    }"
    >Уволить</Button
  >
  <Button
    v-else
    icon="pi-history"
    :style="{
      width: '186px',
    }"
    >Принять заново</Button
  >

  <FiredModal
    :isShowModal="employeesStore.isShowFiredModal"
    title="Уволить сотрудника?"
    @close="employeesStore.hideFiredModal()"
    @delete="fireEmployee"
  />
</template>

<script setup>
import FiredModal from "@/components/modals/FiredModal.vue";
import Button from "@/components/ui/Button/Button.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";
import { useRoute } from "vue-router";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications";

const route = useRoute();
const employeesStore = useEmployeesStore();
const isBanned = employeesStore.employee.banned.value;

const fireEmployee = () => {
  EmployeesAPI.ban(route.params.id)
    .then(() => {
      notify({
        type: "success",
        text: "Пользователь уволен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>
