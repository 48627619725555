<template>
  <Button
    icon="pi-plus"
    @click="employeesStore.setShowAddAccountForm(true)"
    :style="{
      width: '262px',
    }"
    >Добавить учетную запись</Button
  >

  <SideMenu
    @close="closeForm"
    v-if="employeesStore.isShowAddAccountForm"
    title="Добавить учетную запись"
    width="804"
  >
    <template #body>
      <AccountForm />
    </template>
    <template #footer>
      <Button
        variant="solid"
        color="secondary"
        @click="closeForm"
        :style="{
          width: '170px',
        }"
      >
        Отмена
      </Button>
      <Button
        @click="addAccount"
        :style="{
          width: '206px',
        }"
      >
        Добавить
      </Button>
    </template>
  </SideMenu>
</template>

<script setup>
import SideMenu from "@/components/ui/SideMenu/SideMenu.vue";
import { useEmployeesStore } from "../../model/store/useEmployeesStore.js";
import Button from "@/components/ui/Button/Button.vue";
import { EmployeesAPI } from "@/api/EmployeesAPI/index.js";
import { notify } from "@kyvg/vue3-notification";
import notifications from "@/helpers/notifications.js";
import AccountForm from "@/views/Accounts/ui/components/AccountForm.vue";

const employeesStore = useEmployeesStore();

const closeForm = () => {
  employeesStore.setShowAddAccountForm(false);
};

const addAccount = () => {
  if (!employeesStore.validateForm()) {
    return;
  }
  EmployeesAPI.add(employeesStore.getValues)
    .then(() => {
      window.dispatchEvent(new CustomEvent("updateTable"));
      notify({
        type: "success",
        text: "Аккаунт успешно добавлен",
      });
    })
    .catch((err) => {
      notifications(err);
    });
};
</script>
