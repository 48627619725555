<template>
  <div class="form">
    <div class="form-group">
      <div class="item">
        <FormInput
          v-model="formData.surname.value"
          type="text"
          label="Фамилия"
          size="small"
          placeholder="Введите фамилию"
          :disabled="disabled"
          :error-condition="formData.surname.error"
        />
      </div>
      <div class="item">
        <FormInput
          v-model="formData.name.value"
          type="text"
          label="Имя"
          size="small"
          placeholder="Введите Имя"
          :disabled="disabled"
          :error-condition="formData.name.error"
        />
      </div>
    </div>
    <div class="form-group-full">
      <div class="item">
        <FormInput
          v-model="formData.patronymic.value"
          type="text"
          label="Отчество"
          size="small"
          placeholder="Введите Отчество"
          :disabled="disabled"
          :error-condition="formData.patronymic.error"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="item">
        <FormInput
          v-model="formData.email.value"
          type="text"
          label="Email"
          size="small"
          placeholder="Введите Email"
          :disabled="disabled"
          :error-condition="formData.email.error"
        />
      </div>
      <div class="item">
        <FormDate
          v-model="formData.birthday"
          placeholder="Введите Дату рождения"
          :disabled="disabled"
          label="Дата рождения"
          :error-condition="formData.birthday.error"
        />
      </div>
    </div>

    <div class="item">
      <FormSelect
        v-model="formData.departmentId.value"
        :items="departments"
        label="Подразделение"
        placeholder="Выберите подразделение"
        :disabled="disabled"
        :error-condition="formData.departmentId.error"
      />
    </div>

    <div class="item">
      <FormSelect
        v-model="formData.positionId.value"
        :items="positions"
        label="Должность"
        placeholder="Выберите должность"
        :disabled="disabled"
        :error-condition="formData.positionId.error"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { DepartmentsAPI } from "@/api/DepartmentsAPI";
import { PositionsAPI } from "@/api/PositionsAPI";
import { useEmployeesStore } from "../../model/store/useEmployeesStore";

defineProps({
  disabled: { type: Boolean, default: false },
});

const employeesStore = useEmployeesStore();

const formData = employeesStore.employee;

const departments = ref([]);
const positions = ref([]);

onMounted(() => {
  DepartmentsAPI.getAll().then((res) => {
    departments.value = res.data;
  });
  PositionsAPI.getAll().then((res) => {
    positions.value = res.data;
  });
});
</script>
