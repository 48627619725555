<template>
  <Modalka
    v-if="props.isShowModal"
    :loading="false"
    @add="addEmployee"
    actionText="Добавить"
    :headerText="`Добавить пользователя`"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <form class="modal-form">
        <div>
          <FormSelect
            v-model="form.name.value"
            :validation="form.name"
            :items="employees"
            label="Пользователь"
            placeholder="Выберите пользователя"
          />
          <FormSelect
            v-model="form.subdivision.value"
            :validation="form.subdivision"
            :items="subdivisions"
            label="Подразделение"
            placeholder="Выберите подразделение"
          />
          <FormSelect
            v-model="form.job.value"
            :validation="form.job"
            :items="jobs"
            label="Должность"
            placeholder="Выберите должность"
          />
        </div>
      </form>
    </template>
  </Modalka>
</template>

<script setup>
import Modalka from "@/components/modals/Modalka";
import { ref } from "vue";
import { AddEmployeeForm } from "./AddEmployeeForm";

// Определяем реактивные данные и функции
const form = AddEmployeeForm();
const employees = [
  { key: "Куликов Иван Владимирович", id: "1" },
  { key: "Перелеска Ирина Витальевна", id: "2" },
];
const subdivisions = ref([
  { id: "1", name: "Отдел Информационных Технологий" },
  { id: "2", name: "Бухгалтерия" },
  { id: "3", name: "Управление проектами" },
  { id: "4", name: "Техническая дирекция" },
  { id: "5", name: "Отдел качества" },
  { id: "6", name: "Экономическая безопасность" },
]);
const jobs = ref([
  { id: "1", name: "Администратор" },
  { id: "2", name: "Разработчик" },
  { id: "3", name: "Главный бухгалтер" },
  { id: "4", name: "Бухгалтер" },
  { id: "5", name: "Руководитель отдела" },
  { id: "6", name: "Инженер" },
]);

// Пропсы компонента
const props = defineProps({
  isShowModal: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
.modal-form {
  width: 100%;
}
</style>
