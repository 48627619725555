<template>
  <div class="card">
    <div class="card__header">
      <div class="card__title">
        <h3 class="title">Список ролей</h3>
      </div>
      <div class="card__actions">
        <Button
          icon="pi-pencil"
          variant="outlined"
          color="default"
          @click="rolesStore.toggleEditMode()"
          :active="rolesStore.isEditMode"
        />
      </div>
    </div>
    <div class="card__body">
      <Catalog
        :items="roles"
        :is-editable="true"
        :disabled="!rolesStore.isEditMode"
      />
    </div>

    <div class="card__footer" v-if="rolesStore.isEditMode">
      <Button
        class="btn-cancel"
        variant="solid"
        color="secondary"
        :style="{
          width: '160px',
        }"
      >
        Отмена
      </Button>
      <Button
        class="btn-accept"
        :style="{
          width: '206px',
        }"
      >
        Сохранить
      </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import Catalog from "@/views/Roles/components/Catalog.vue";
import { roles } from "@/views/Roles/model/consts/catalog";
import { useRolesStore } from "@/views/Roles/model/store/useRolesStore";

const rolesStore = useRolesStore();
</script>
