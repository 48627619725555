<template>
  <Tabs
    v-model="activeTab"
    :tabs="[
      {
        title: 'Общая информация',
        component: EmployeeInfo,
      },
      {
        title: 'Роли пользователя',
        component: EmployeeRoles,
      },
      {
        title: 'Учетные записи',
        component: EmployeeAccounts,
      },
    ]"
  >
    <template #extra>
      <template v-if="activeTab === 0">
        <Button
          icon="pi-pencil"
          variant="outlined"
          color="default"
          :active="employeesStore.isEditMode"
          @click="employeesStore.toggleEditMode()"
        />
        <Button
          icon="pi-trash"
          variant="outlined"
          color="danger"
          @click="employeesStore.showDeleteModal()"
        />
        <Button
          variant="outlined"
          color="default"
          icon="pi-sort-alt"
          :style="{
            width: '131px',
          }"
          >Перевод</Button
        >
        <FireEmployee />
      </template>

      <template v-if="activeTab === 1">
        <Button
          variant="outlined"
          color="default"
          icon="pi-history"
          :style="{
            width: '138px',
          }"
          >Обновить</Button
        >

        <AddRole />
      </template>

      <template v-if="activeTab === 2">
        <Button
          variant="outlined"
          color="default"
          icon="pi-history"
          :style="{
            width: '138px',
          }"
          >Обновить</Button
        >
        <AddAccount />
      </template>
    </template>
  </Tabs>
</template>

<script setup>
import Button from "@/components/ui/Button/Button.vue";
import EmployeeInfo from "./ui/pages/Info.vue";
import EmployeeRoles from "./ui/pages/Roles.vue";
import EmployeeAccounts from "./ui/pages/Accounts.vue";
import { useEmployeesStore } from "./model/store/useEmployeesStore";
import Tabs from "@/components/ui/Tabs/Tabs.vue";
import AddRole from "./ui/components/AddRole.vue";
import AddAccount from "./ui/components/AddAccount.vue";
import { ref } from "vue";
import FireEmployee from "./ui/components/FireEmployee.vue";

const employeesStore = useEmployeesStore();

const activeTab = ref(0);
</script>
