<template>
  <div class="content">
    <Loading v-if="loading" style="height: 100vh" />
    <div v-else>
      <div class="table-header">
        <SearchInput
          placeholder="Поиск по ФИО пользователя, Подразделению или Должности..."
        />
        <div class="button-container">
          <EmployeeFilters
            :employees="employees"
            :updateFilteredItems="setFilteredItems"
          />

          <AddEmployee />
        </div>
      </div>
      <Table
        name="employees"
        :columns="columns"
        :data="filteredItems"
        :loading="loading"
      >
        <template #actions="{ item }">
          <Button
            icon="pi-pencil"
            color="secondary"
            variant="outlined"
            rounded="sm"
            size="sm"
            @click="router.push(`/employees/${item.id}?name=${item.name}`)"
          />
          <Button
            icon="pi-trash"
            color="danger"
            variant="outlined"
            rounded="sm"
            size="sm"
            @click="remove(employees, item.id, 'employees', '/employees')"
          />
        </template>

        <template #dropdown-actions="{ item }">
          <li>Перевод</li>
          <li v-if="item.banned">Принять заново</li>
          <li v-else>Уволить</li>
        </template>
      </Table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { remove } from "@/composables/actions";
import { useRouter } from "vue-router";
import { EmployeesAPI } from "@/api/EmployeesAPI";
import Table from "@/components/table/Table";
import SearchInput from "@/components/table/SearchInput";
import Loading from "@/components/Loading";
import Button from "@/components/ui/Button/Button.vue";
import EmployeeFilters from "./ui/components/EmployeeFilters.vue";
import AddEmployee from "./ui/components/AddEmployee.vue";
import { useEmployeesStore } from "./model/store/useEmployeesStore";

const router = useRouter();
const employeesStore = useEmployeesStore();
const loading = ref(false);
const employees = ref([]);

const fetchData = () => {
  loading.value = true;
  EmployeesAPI.getAll()
    .then((res) => {
      employees.value = res.data.map((item) => ({
        ...item,
        department_name: item.department?.name,
        position_name: item.position?.name,
      }));
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  employeesStore.resetEmployee();
  fetchData();
  window.addEventListener("updateTable", fetchData);
});

const columns = {
  id: { value: "ID" },
  name: { value: "ФИО пользователя" },
  department_name: { value: "Подразделение" },
  position_name: { value: "Должность" },
};

const filteredItems = computed(() =>
  employees.value.map((employee) => ({ ...employee }))
);

const setFilteredItems = (items) => {
  filteredItems.value = items;
};

onUnmounted(() => {
  window.removeEventListener("updateTable", fetchData);
});
</script>
