<template>
  <div class="page">
    <Steps :steps="3" :activeStep="formStore.activeStep" />

    <FormAddInfo v-if="formStore.activeStep === 1" />
    <AddRoles v-if="formStore.activeStep === 2" />
    <AddEmployees v-if="formStore.activeStep === 3" />
  </div>
</template>
<script setup>
import { onUnmounted } from "vue";
import FormAddInfo from "./steps/FormAddInfo.vue";
import Steps from "../../components/Steps.vue";
import AddRoles from "./steps/AddRoles.vue";
import AddEmployees from "./steps/AddEmployees.vue";
import { useFormStore } from "../../model/store/useFormStore";

const formStore = useFormStore();

onUnmounted(() => {
  formStore.setActiveStep(1);
});
</script>
