<template>
  <div class="field">
    <label for="">{{ label }}</label>
    <div class="field__input">
      <textarea
        v-if="type === 'textarea'"
        rows="7"
        :placeholder="placeholder"
        :disabled="disabled"
        :value="modelValue"
        v-bind="$attrs"
        @blur="validation.blur"
        @input="updateValue"
        :class="[size, { field_error: errorCondition }]"
      ></textarea>
      <input
        v-else-if="type === 'tel'"
        v-mask="'+# (###) ### ## ##'"
        :type="isType"
        v-bind="$attrs"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="validation.blur"
        @input="updateValue"
        :class="[
          size,
          {
            field_error: errorCondition,
            password: type === 'password',
          },
        ]"
      />
      <input
        v-else-if="type === 'text' && vmask !== null"
        :type="isType"
        v-bind="$attrs"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :min="max"
        :max="max"
        :maxlength="maxLength"
        v-mask="vmask"
        @blur="validation.blur"
        @input="updateValue"
        :autocomplete="autocomplete"
        :class="[
          size,
          {
            field_error: errorCondition,
            password: type === 'password',
          },
        ]"
      />
      <input
        v-else
        :type="isType"
        v-bind="$attrs"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="validation.blur"
        @input="updateValue"
        :class="[
          size,
          {
            field_error: errorCondition,
            password: type === 'password',
          },
        ]"
      />
      <button v-if="type === 'password'" type="button" @click="toggleVisible">
        <svg
          width="24px"
          role="img"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
          class="v-icon"
        >
          <use :href="visible ? '#password-hide' : '#password-visible'" />
        </svg>
      </button>
    </div>
    <span class="field__description" v-if="desctiptionText != ''"></span>
    <div class="field-error" v-if="errorCondition">{{ errorText }}</div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";

// Определяем свойства компонента
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  validation: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "",
  },
  max: {
    type: String,
    default: "",
  },
  maxLength: {
    type: String,
    default: "",
  },
  vmask: {
    type: String,
    default: null,
  },
  autocomplete: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: "",
  },
  descriptionText: {
    type: String,
    default: "",
  },
  errorText: {
    type: String,
    default: "Поле обязательно к заполнению",
  },
  errorCondition: {
    type: Boolean,
    default: false,
  },
});

// Определяем события компонента
const emit = defineEmits(["update:modelValue"]);

const visible = ref(false);
const isType = computed(() =>
  props.type === "password" ? (visible.value ? "text" : "password") : props.type
);

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};

const toggleVisible = () => {
  visible.value = !visible.value;
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 10px;
}
.field {
  margin-bottom: 24px;
  width: 100%;
  &__input {
    display: flex;
    align-items: center;
    position: relative;
    color: $theme800;
    textarea {
      width: 100%;
      border: 1px solid #d1d8ec;
      border-radius: 6px;
      margin-top: 8px;
      outline: none;
      position: relative;
      overflow: scroll;
      transition: 0.3s;
      font-size: 16px;
      padding: 15px;
      &.field_error {
        border: 1px solid #e21e4d;
      }
      &:focus {
        border: 1px solid #5bc4d1;
        box-shadow: 0 0 0 5px rgb(91 196 209 / 5%);
      }
    }
    &:focus {
      border: 1px solid #5bc4d1;
    }
  }
  &-error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    color: #e21e4d;
  }
  label {
    color: #3e445b;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }
  input {
    padding-left: 15px;
    font-size: 16px;
    width: 100%;
    outline: none;
    border: 1px solid #d1d8ec;
    border-radius: 6px;
    height: 56px;
    margin-top: 8px;
    background: #fff;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    &.small {
      height: 48px;
    }
    &.field_error {
      border: 1px solid #e21e4d;
    }
    &:focus {
      border: 1px solid #5bc4d1;
      box-shadow: 0 0 0 5px rgb(91 196 209 / 5%);
    }
    &::placeholder {
      font-size: 14px;
    }
  }
}
button {
  outline: none;
  background: none;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 13px;
  top: 22px;
}
.password {
  padding-right: 50px;
}
</style>
