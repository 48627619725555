<template>
  <div class="date">
    <label for="icondisplay" class="date-label" v-if="label">
      {{ label }}
    </label>
    <Calendar
      inputId="icondisplay"
      class="date-picker"
      v-model="selectedDate"
      @update:model-value="$emit('update:modelValue', selectedDate)"
      v-bind="$attrs"
    />
    <span :class="['icon', { noLabel: !label }]"
      ><i class="pi pi-calendar"></i
    ></span>
  </div>
  <div class="date-error" v-if="errorCondition">{{ errorText }}</div>
</template>

<script setup>
import Calendar from "primevue/calendar";
import { ref } from "vue";

defineProps({
  modelValue: Date,
  label: {
    type: String,
    default: null,
  },
  errorText: {
    type: String,
    default: "Поле обязательно к заполнению",
  },
  errorCondition: {
    type: Boolean,
    default: false,
  },
});

const selectedDate = ref(null);
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.date {
  position: relative;
}
.date-label {
  color: #3e445b;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}
.date-picker {
  display: flex;
  align-items: center;
  position: relative;
  color: #3e445b;
}
.icon {
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(5px);

  &.noLabel {
    transform: translateY(-5px);
  }
}
.date-error {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  color: #e21e4d;
}
</style>
