<template>
  <div class="catalog">
    <div class="catalog-search">
      <SearchInput
        class="search-input"
        v-model="searchTerm"
        placeholder="Поиск ролей по названию "
        @input="resetSelectedItem"
      />
      <div class="chip-container" v-if="selectedItems.length && !disabled">
        <Chip
          v-for="item in selectedItems"
          :key="item.id"
          :label="item.title"
          removable
          @remove="removeItem(item)"
        />
        <Button
          variant="outlined"
          color="secondary"
          icon="pi-chevron-down"
          @click="toggle"
          :style="{ width: '36px', height: '36px' }"
        />
        <Button variant="outlined" color="secondary" @click="removeAllItems()"
          >Сбросить все</Button
        >

        <OverlayPanel ref="op">
          <div class="overlay">
            <div class="overlay-header">
              <h3>Список выбранных ролей</h3>
            </div>
            <div class="overlay-body">
              <div
                v-for="item in selectedItems"
                :key="item.id"
                class="overlay-item"
              >
                <p>{{ item.title }}</p>
                <i class="pi pi-times" @click="removeItem(item)"></i>
              </div>
            </div>
            <div class="overlay-footer">
              <Button color="secondary">Отмена</Button>
              <Button @click="removeAllItems()">Сбросить все</Button>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </div>
    <div class="catalog-content">
      <div class="catalog-list">
        <div
          class="catalog-item"
          v-for="item in displayedItems"
          :key="item.title"
          @click="selectItem(item.id)"
          :class="{ active: selectedItem === item.id }"
        >
          <div class="catalog-item-title">
            <img
              v-if="!isEditable"
              src="../model/assets/catalog-icon.svg"
              alt="Catalog icon"
            />
            <Checkbox
              v-else
              :disabled="disabled"
              :id="'checkbox-' + item.id"
              v-model="selectedItemsMap[item.title]"
              @update:modelValue="(value) => toggleSelection(item, value)"
              @click.stop
            />
            <div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
            </div>
          </div>
          <div class="catalog-item-icon">
            <img src="../model/assets/triangle.svg" alt="Catalog icon" />
          </div>
        </div>
      </div>

      <div
        class="catalog-card"
        v-if="selectedItem !== null && searchTerm === ''"
      >
        <div class="catalog-card-header">
          <h3 v-if="isEditable">Список прав доступа</h3>
          <h3 v-else>{{ items[selectedItem - 1].title }}</h3>
        </div>
        <div class="catalog-card-body">
          <div
            :class="[
              'catalog-card-item',
              { 'is-link': isRouter, muted: isEditable },
            ]"
            v-for="subItem in items[selectedItem - 1].items"
            :key="subItem.id"
            @click="routeHandler(subItem.id, subItem.title)"
          >
            <h4>{{ subItem.title }}</h4>
            <p>{{ subItem.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SearchInput from "@/components/table/SearchInput.vue";
import Button from "@/components/ui/Button/Button.vue";
import Checkbox from "@/components/ui/Checkbox/Checkbox.vue";
import Chip from "primevue/chip";
import OverlayPanel from "primevue/overlaypanel";
import { computed } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  items: Array,
  isRouter: { type: Boolean, default: false },
  isEditable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
});

const selectedItem = ref(null);
const selectedItems = ref([]);
const searchTerm = ref("");
const selectedItemsMap = ref({});
const router = useRouter();
const op = ref();

const toggle = (event) => {
  op.value.toggle(event);
};

// Функция для выбора элемента
const selectItem = (index) => {
  if (selectedItem.value === index) {
    selectedItem.value = null;
    return;
  }
  selectedItem.value = index;
};

const resetSelectedItem = () => {
  selectedItem.value = null;
};

const isSelected = (item) => {
  return selectedItems.value.some(
    (selectedItem) => selectedItem.title === item.title
  );
};

const toggleSelection = (item) => {
  if (isSelected(item)) {
    // Удаляем элемент, если он уже выбран
    selectedItems.value = selectedItems.value.filter(
      (selectedItem) => selectedItem.title !== item.title
    );
  } else {
    // Добавляем элемент, если он не выбран
    selectedItems.value.push(item);
  }
};

const removeItem = (item) => {
  selectedItems.value = selectedItems.value.filter(
    (selectedItem) => selectedItem.title !== item.title
  );
  selectedItemsMap.value[item.title] = false; // Сбрасываем чекбокс
};

const removeAllItems = () => {
  selectedItems.value = [];
  selectedItemsMap.value = {};
};

const displayedItems = computed(() => {
  if (props.disabled) {
    return selectedItems.value;
  } else if (searchTerm.value) {
    return props.items.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.value.toLowerCase())
    );
  }
  return props.items;
});

const routeHandler = (id, name) => {
  if (!props.isRouter) {
    return;
  }
  router.push(`/roles/catalog/${id}?name=${name}`);
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars";
@import "@/assets/scss/_mixins";

.catalog {
  &-content {
    background-color: $theme100;
    border-radius: 9px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 24px 96px 24px 24px;
    max-height: 611px;
    overflow: hidden;
  }

  &-search {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chip-container {
      display: flex;
      align-items: center;
      gap: 12px;

      button {
        width: 127px;
        height: 36px;
        padding: 0 12px;
        font-size: 14px;
        margin-left: 12px;
      }
    }

    .search-input {
      width: 418px;
    }
  }

  &-item {
    background-color: white;
    border: 1px solid $theme200;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 8px;
    transition: background-color 0.25s ease;

    &:hover,
    &.active {
      background-color: $theme200;
    }

    &.active {
      .catalog-item-icon {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &-icon {
      img {
        transition: transform 0.25s ease;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 16px;

      h3 {
        font-size: 20px;
        font-weight: 700;
        color: $theme800;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
        color: $theme500;
      }
    }
  }

  &-card {
    background-color: white;
    border-radius: 9px;
    border: 1px solid $theme200;

    &-header {
      padding: 24px;
      border-bottom: 1px solid $theme200;
      h3 {
        font-weight: 700;
        color: $theme900;
        font-size: 20px;
        line-height: 28px;
      }
    }
    &-body {
      padding: 24px;
      max-height: 460px;
      overflow-y: auto;
    }
    &-item {
      margin-bottom: 16px;
      &.is-link {
        cursor: pointer;
      }
      &.muted {
        margin-bottom: 16px;
        h4 {
          color: $theme500;
        }
      }
      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 4px;
        color: $theme800;
      }
      p {
        font-size: 14px;
        line-height: 14px;
        color: $theme500;
      }
    }
  }
}

.overlay {
  width: 402px;

  &-header {
    padding: 24px 32px 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $theme200;

    h3 {
      color: $theme800;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &-body {
    padding: 0 16px 8px;
    max-height: 370px;
    overflow-y: auto;
    @include scrollbar();
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.25s ease;
    &:hover {
      background-color: $theme100;
    }
    p {
      line-height: 24px;
      color: $text;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 21px;
    padding: 16px 16px 24px;

    button:first-child {
      width: 150px;
    }
    button:last-child {
      width: 200px;
    }
  }
}
</style>
