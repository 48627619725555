<template>
  <div v-click-outside="onClickOutside" class="select">
    <div class="select__label">{{ label }}</div>
    <div
      class="selected"
      :class="{
        open: isOpen,
        field_error: errorCondition,
      }"
      @click="toggleSelect"
    >
      <div class="selected-container">
        <span v-if="!searchable || !isOpen" :class="{ grey: !selectedItem }">{{
          selectedName || placeholder
        }}</span>
        <input
          v-if="searchable && isOpen"
          ref="searchInput"
          v-model="searchQuery"
          type="text"
          class="select__search"
          placeholder="Поиск..."
          @click.stop
        />
        <i class="pi pi-chevron-down" v-if="!searchable || !isOpen"></i>
        <i
          class="pi pi-times"
          v-if="searchable && isOpen"
          @click.stop="clearSearch"
        ></i>
      </div>
    </div>
    <div class="select__dropdown" :class="{ open: isOpen }">
      <ul v-if="filteredItems.length">
        <li v-for="item in filteredItems" :key="item.id">
          <button type="button" @click="select(item)">
            {{
              item.auth_type ||
              item.key ||
              item.protocol_type ||
              item.name ||
              item.fio
            }}
          </button>
        </li>
      </ul>
      <div v-else class="select__empty">
        По запросу <b>{{ searchQuery }}</b> ничего не найдено
      </div>
    </div>
    <div class="select-error" v-if="errorCondition">{{ errorText }}</div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { defineProps, defineEmits } from "vue";

// Определяем свойства компонента
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  items: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "",
  },
  validation: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  labelProp: {
    type: String,
    default: "",
  },
  modelValue: {
    type: [String, Number],
  },
  errorText: {
    type: String,
    default: "Поле обязательно к заполнению",
  },
  errorCondition: {
    type: Boolean,
    default: false,
  },
  searchable: {
    type: Boolean,
    default: false, // Новый проп для включения поиска
  },
});

// Определяем события компонента
const emit = defineEmits(["update:modelValue"]);

const isOpen = ref(false);
const searchQuery = ref("");
const searchInput = ref(null);

const clearSearch = () => {
  searchQuery.value = "";
};

const selectedItem = computed(() =>
  props.items.find((item) => item.id === props.modelValue)
);

const selectedName = computed(() => {
  if (!selectedItem.value) {
    return props.modelValue;
  }

  return (
    selectedItem.value[props.labelProp] ||
    selectedItem.value.auth_type ||
    selectedItem.value.key ||
    selectedItem.value.protocol_type ||
    selectedItem.value.name ||
    selectedItem.value.fio ||
    props.modelValue
  );
});

const filteredItems = computed(() => {
  return props.items.filter((item) => {
    const name =
      item[props.labelProp] ||
      item.auth_type ||
      item.key ||
      item.protocol_type ||
      item.name ||
      item.fio;

    return name.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
});

const toggleSelect = () => {
  if (props.disabled) return;
  isOpen.value = !isOpen.value;

  // Сброс строки поиска при закрытии и автофокус при открытии
  if (isOpen.value) {
    nextTick(() => {
      searchInput.value && searchInput.value.focus(); // Автофокус на поле поиска
    });
  } else {
    searchQuery.value = ""; // Очищаем строку поиска при закрытии
  }
};

watch(isOpen, (newOpen) => {
  // eslint-disable-next-line vue/no-mutating-props
  props.validation.touched = !newOpen;
});

const select = (item) => {
  isOpen.value = false;
  emit("update:modelValue", item.id);
};

const onClickOutside = () => {
  isOpen.value = false;
  searchQuery.value = "";
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars";

.selected.field_error {
  border: 1px solid #e21e4d;
}
.selected-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pi {
    color: $theme800;
  }
}
.select {
  margin-bottom: 24px;
  position: relative;
  &-error {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    color: #e21e4d;
  }
  &__search {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
  &__empty {
    padding: 22px 12px;
    line-height: 24px;
  }
  &__label {
    color: #3e445b;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }
  &__dropdown {
    border: 1px solid #d1d8ec;
    border-radius: 6px;
    margin-top: 5px;
    padding: 5px;
    display: none;
    background: #fff;
    &.open {
      display: block;
      position: absolute;
      z-index: 99;
      right: 0;
      max-height: 50vh;
      overflow: auto;
    }
    ul {
      li {
        button {
          color: #3e445b;
          width: 100%;
          text-align: left;
          font-size: 16px;
          padding: 10px 9px;
          font-weight: 400;
          &:hover {
            background: #f8f9fc;
          }
        }
      }
    }
  }
}
.selected {
  background: white;
  border: 1px solid #d1d8ec;
  border-radius: 6px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  margin-top: 8px;
  position: relative;
  transition: 0.3s;
  color: $theme800;
  &.open {
    border: 1px solid #5bc4d1;
    box-shadow: 0 0 0 5px rgb(91 196 209 / 5%);
    /* &:before {
      transform: rotate(-180deg);
    } */
  }
  /* &:before {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNSAxTDggOEwxIDEiIHN0cm9rZT0iIzU3NUU3NiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    width: 16px;
    height: 9px;
    background-repeat: no-repeat;
    right: 16px;
    top: 19px;
  } */
}
.grey {
  color: #757575;
  font-size: 14px;
}
</style>
